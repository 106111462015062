import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestData } from '../../request';
import { message } from 'antd';
import { getSession } from '../../../lib/Auth';

// List all contracts Agent leads
export const listContractsAgentLeads = createAsyncThunk(
  'agentleads/list/agent/leads',
  async (agent_id, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agents/${agent_id}/dashboard`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// List all contracts  Agent leads search
export const listContractsAgentLeadsSearch = createAsyncThunk(
  'agentleads/list/filters/search',
  async ({ agent_id, name }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agents/${agent_id}/lead_listing?search=${name}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// contracts slice
const contractsAgentLeadsSlice = createSlice({
  name: 'agentleads',
  initialState: {
    agentleads: [],
    meta: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // List contracts filters
      .addCase(listContractsAgentLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listContractsAgentLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.agentleads = action.payload?.leads;
        state.meta = action.payload?.meta;
      })
      .addCase(listContractsAgentLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch contract leads';
      })

      // List contracts search
      .addCase(listContractsAgentLeadsSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listContractsAgentLeadsSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.agentleads = action.payload?.leads;
        state.meta = action.payload?.meta;
      })
      .addCase(listContractsAgentLeadsSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch contract leads';
      });
  }
});

export default contractsAgentLeadsSlice.reducer;
