import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestData } from '../../request';
import { message } from 'antd';
import { getSession } from '../../../lib/Auth';

// Create an contract
export const createContract = createAsyncThunk(
  'contracts/create',
  async (contractData, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: 'api/v1/contracts',
          method: 'POST',
          data: contractData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        message.success('Contract created successfully');
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);
// leads cap
export const createContractLeadCap = createAsyncThunk(
  'contracts/create/leadcap',
  async ({ leadCapData, contract_id, agency_id }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agencies/${agency_id}/contracts/${contract_id}/lead_caps`,
          method: 'POST',
          data: leadCapData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        message.success('Contract created successfully');
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

export const updateContractLeadCap = createAsyncThunk(
  'contracts/update/leadcap',
  async ({ leadCapData, contract_id, agency_id, id }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agencies/${agency_id}/contracts/${contract_id}/lead_caps/${id}`,
          method: 'PUT',
          data: leadCapData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        message.success('Contract lead Cap updated successfully');
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Create an contract for an agency
export const createContractAgency = createAsyncThunk(
  'contracts/create',
  async ({ id, contractData }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agencies/${id}/contracts`,
          method: 'POST',
          data: contractData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        message.success('Contract created successfully');
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

export const updateContractAgency = createAsyncThunk(
  'contracts/update/agency',
  async ({ id, contractId, contractData }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agencies/${id}/contracts/${contractId}`,
          method: 'PUT',
          data: contractData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        message.success('Contract updated successfully');
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);
// List all contracts
export const listContracts = createAsyncThunk(
  'contracts/list',
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const queryParams = new URLSearchParams({
        page: page.toString(), // Convert to string for URL compatibility
        pageSize: pageSize.toString() // Convert to string for URL compatibility
      }).toString();
      const data = await requestData(
        {
          url: `api/v1/contracts?${queryParams}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// List all contracts search
export const listContractsSearch = createAsyncThunk(
  'contracts/list/search',
  async (name, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/contracts?search=${name}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);
// List contracts of agency
export const listContractsOfAgency = createAsyncThunk(
  'contracts/list/agency',
  async ({ id, page, pageSize }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const queryParams = new URLSearchParams({
        page: page.toString(), // Convert to string for URL compatibility
        pageSize: pageSize.toString() // Convert to string for URL compatibility
      }).toString();
      const data = await requestData(
        {
          url: `api/v1/agencies/${id}/contracts?${queryParams}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);
// List contracts of agency search
export const listContractsOfAgencySearch = createAsyncThunk(
  'contracts/list/agency/search',
  async ({ name, id }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agencies/${id}/contracts?search=${name}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Update an contract
export const updateContract = createAsyncThunk(
  'contracts/update',
  async ({ id, contractData }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/contracts/${id}`,
          method: 'PUT',
          data: contractData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        message.success('Contract updated successfully');
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Update an contract
export const updateContract2step = createAsyncThunk(
  'contracts/updatestep2',
  async ({ id, contractData }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/contracts/${id}`,
          method: 'PUT',
          data: contractData,
          token: token?.token
        },
        { rejectWithValue }
      );
      message.success('Contract updated successfully');
      return data;
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Delete an contracts
export const deleteContract = createAsyncThunk(
  'contracts/delete',
  async (id, { rejectWithValue }) => {
    try {
      const token = getSession();
      await requestData(
        {
          url: `api/v1/contracts/${id}`,
          method: 'DELETE',
          token: token?.token
        },
        { rejectWithValue }
      );
      message.success('Contract deleted successfully');
      return id;
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

export const ContractFetch = createAsyncThunk(
  'contracts/contract',
  async (id, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/contracts/${id}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      return data;
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

export const updateContractAgecyStatus = createAsyncThunk(
  'contracts/update/agency/status',
  async ({ id, contractId }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agencies/${id}/contracts/${contractId}/update_contract_status`,
          method: 'PUT',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        message.success('Contract updated successfully');
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// contracts slice
const contractsSlice = createSlice({
  name: 'contracts',
  initialState: {
    contracts: [],
    meta: null,
    contract: null,
    leadcap: null,
    loading: false,
    error: null
  },
  reducers: {
    ContractCreateUser: (state, action) => {
      state.contract = action.payload.contract;
    }
  },
  extraReducers: (builder) => {
    builder

      // fetch Contract
      .addCase(ContractFetch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ContractFetch.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.contract = action.payload;
      })

      .addCase(ContractFetch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to get contract';
      })
      // Create contract
      .addCase(createContract.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createContract.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.contract = action.payload.contract;
        state.contracts = [action.payload.contract, ...state.contracts];
      })

      .addCase(createContract.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to create contract';
      })

      // Create contract leadCap
      .addCase(createContractLeadCap.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createContractLeadCap.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.leadcap = action.payload.leadcap;
      })

      .addCase(createContractLeadCap.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to create contract leadCap';
      })

      // Update contract leadCap
      .addCase(updateContractLeadCap.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateContractLeadCap.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.leadcap = action.payload.leadcap;
      })
      .addCase(updateContractLeadCap.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update contract leadCap';
      })

      // List contracts
      .addCase(listContracts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listContracts.fulfilled, (state, action) => {
        state.loading = false;
        state.contracts = action.payload?.contracts;
        state.meta = action.payload?.meta;
      })
      .addCase(listContracts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch contract';
      })

      // List contracts search
      .addCase(listContractsSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listContractsSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.contracts = action.payload?.contracts;
        state.meta = action.payload?.meta;
      })
      .addCase(listContractsSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch contract';
      })

      // List contracts of agency
      .addCase(listContractsOfAgency.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listContractsOfAgency.fulfilled, (state, action) => {
        state.loading = false;
        state.contracts = action.payload?.contracts;
        state.meta = action.payload?.meta;
      })
      .addCase(listContractsOfAgency.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch contract';
      })

      // List contracts of agency search
      .addCase(listContractsOfAgencySearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listContractsOfAgencySearch.fulfilled, (state, action) => {
        state.loading = false;
        state.contracts = action.payload?.contracts;
        state.meta = action.payload?.meta;
      })
      .addCase(listContractsOfAgencySearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch contract';
      })

      // Update Contract
      .addCase(updateContract.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateContract.fulfilled, (state, action) => {
        state.loading = false;
        state.contracts = state.contracts.map((contract) =>
          contract.id === action.payload.contract?.id
            ? { ...contract, ...action.payload.contract }
            : contract
        );
        state.contract = action.payload.contract;
      })
      .addCase(updateContract.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update contract';
      })

      // Update Contract Agency
      .addCase(updateContractAgency.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateContractAgency.fulfilled, (state, action) => {
        state.loading = false;
        state.contracts = state.contracts.map((contract) =>
          contract.id === action.payload.contract?.id
            ? { ...contract, ...action.payload.contract }
            : contract
        );
        state.contract = action.payload.contract;
      })
      .addCase(updateContractAgency.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update contract';
      })

      // Update contract 2nd step
      .addCase(updateContract2step.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateContract2step.fulfilled, (state, action) => {
        state.loading = false;
        state.contracts = state.contracts.map((contract) =>
          contract.id === action.payload.contract?.id
            ? { ...contract, ...action.payload.contract }
            : contract
        );
        state.contract = null;
      })
      .addCase(updateContract2step.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update contract';
      })

      // Delete contract
      .addCase(deleteContract.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteContract.fulfilled, (state, action) => {
        state.loading = false;
        state.contracts = state.contracts.filter(
          (contract) => contract.id !== action.payload
        );
      })
      .addCase(deleteContract.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to delete contract';
      })

      // update contract status
      .addCase(updateContractAgecyStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateContractAgecyStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.contracts = state.contracts.map((contract) =>
          contract.id === action.payload.contract?.id
            ? { ...contract, ...action.payload.contract }
            : contract
        );
      })
      .addCase(updateContractAgecyStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update contract';
      });
  }
});

export const { ContractCreateUser } = contractsSlice.actions;
export default contractsSlice.reducer;
