import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { removeSession } from '../../../lib/Auth';
import {
  onSubmitLoginForm,
  onSubmitRegisterForm
} from '../../../serverActions/AuthAction';
import { getSession } from '../../../lib/Auth';
import { message } from 'antd';
import { json } from 'react-router-dom';
import { requestDataWithoutToken } from '../../request';

export const login = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const data = await onSubmitLoginForm(credentials);
      return data;
    } catch (error) {
      message.error('Invalid username or password');
      return rejectWithValue(error.message);
    }
  }
);

export const signup = createAsyncThunk(
  'auth/signup',
  async (credentials, { rejectWithValue }) => {
    try {
      const data = await onSubmitRegisterForm({}, credentials);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/reset',
  async ({ credentials, agency_id }, { rejectWithValue }) => {
    try {
      const options = {
        url: `api/v1/agencies/${agency_id}/agents/update_password`,
        method: 'PATCH',
        data: credentials
      };
      const data = await requestDataWithoutToken(options, { rejectWithValue });
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      await removeSession();
      return true;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// New action to set user data from the session on page load
export const setUserFromSession = createAsyncThunk(
  'auth/setUserFromSession',
  async (_, { rejectWithValue }) => {
    try {
      const session = getSession();

      if (session) {
        return session;
      } else {
        return null;
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload || null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Login failed';
      });

    builder
      .addCase(signup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload || null;
      })
      .addCase(signup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Signup failed';
      });

    builder
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.error = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Logout failed';
      })

      .addCase(setUserFromSession.fulfilled, (state, action) => {
        state.user = action.payload || null;
      })
      .addCase(setUserFromSession.rejected, (state, action) => {
        state.error = action.payload || 'Session retrieval failed';
      });
  }
});

export default authSlice.reducer;
